import { EnhancedStore, StateFromReducersMapObject, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import brandStylesReducer from '../domains/publicApplication/brandStyles';
import { publicApplicationApi } from '../services/publicApplicationService';
import { publicConfigApi } from '../services/publicConfigService';

const reducer = {
  brandStyles: brandStylesReducer,
  [publicApplicationApi.reducerPath]: publicApplicationApi.reducer,
  [publicConfigApi.reducerPath]: publicConfigApi.reducer,
};

export type PublicApplicationRootState = StateFromReducersMapObject<typeof reducer>;

let store: EnhancedStore;
export const initStore = () => {
  if (store) return store;

  store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(publicApplicationApi.middleware);
    },
  });

  setupListeners(store.dispatch);

  return store;
};

type Store = ReturnType<typeof initStore>;
export type PublicApplicationDispatch = Store['dispatch'];
