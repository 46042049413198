import React from 'react';

import { usePublicApplicationSelector } from '../../../redux/hooks';
import ToastNotification from '../../design_system/display/ToastNotification/ToastNotification';
import Loader from '../../design_system/Triage/Loader';
import StyleScene from '../../StyleScene';
import { LoaderWrapper } from '../curriculums/shared/styles';
import ApplicationRoutes from './ApplicationRoutes';
import useBrandStyles from './utils/useBrandStyles';

const PublicPageContainer = () => {
  const {
    brandStyles: { accentColor, accentPalette, mode },
  } = usePublicApplicationSelector((store) => store);
  const isLoadingBrandStyles = useBrandStyles();

  return (
    <StyleScene accentColor={accentColor} accentPalette={accentPalette} mode={mode}>
      {isLoadingBrandStyles ? (
        <LoaderWrapper>
          <Loader size='lg' />
        </LoaderWrapper>
      ) : (
        <>
          <ToastNotification />
          <ApplicationRoutes />
        </>
      )}
    </StyleScene>
  );
};

export default PublicPageContainer;
