import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import StyleConfiguration from '../../../types/StyleConfiguration';

const initialState: StyleConfiguration = {
  accentColor: '#6a28ea',
  accentPalette: null,
  mode: 'light',
};

const brandStylesSlice = createSlice({
  name: 'brandStyles',
  initialState,
  reducers: {
    setBrandStyles: (store, action: PayloadAction<StyleConfiguration>) => {
      return { ...store, ...action.payload };
    },
    resetBrandStyles: () => initialState,
  },
});

export const { setBrandStyles, resetBrandStyles } = brandStylesSlice.actions;
export default brandStylesSlice.reducer;
