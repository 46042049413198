import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { Provider } from 'react-redux';

import { ActiveMenuProvider } from '../contexts/ActiveMenuContext';
import { ContentStyleDesignationProvider } from '../contexts/ContentStyleDesignationContext';
import { PublicConfigsProvider } from '../contexts/PublicConfigsContext';
import { initStore } from '../redux/stores/publicApplicationStore';
import { RouteProvider } from './application/publicApplication/applicationRouter';
import PublicPageContainer from './application/publicApplication/PublicPageContainer';
import { FlashNotificationProvider } from './FlashNotificationContext';
import LoadInitialPublicState from './LoadInitialPublicState';
import { ReactInstanceProvider } from './ReactInstanceContext';

const PublicApplicationRoot = () => {
  return (
    <ReactInstanceProvider>
      <Provider store={initStore()}>
        <ContentStyleDesignationProvider designation='trainual'>
          <PublicConfigsProvider>
            <LoadInitialPublicState>
              <FlashNotificationProvider>
                <ActiveMenuProvider>
                  <RouteProvider>
                    <PublicPageContainer />
                  </RouteProvider>
                </ActiveMenuProvider>
              </FlashNotificationProvider>
            </LoadInitialPublicState>
          </PublicConfigsProvider>
        </ContentStyleDesignationProvider>
      </Provider>
    </ReactInstanceProvider>
  );
};

export default PublicApplicationRoot;
