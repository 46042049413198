// Vite - Virtual Files
import '@generate_theme_files';

import * as React from 'react';
import { render } from 'react-dom';

import PublicApplicationRoot from '../react/components/PublicApplicationRoot';
import { initializeFonts } from '../react/lib/font_libraries';

initializeFonts();

// Create root react application
const domNode = document.getElementById('public-react-application');
render(<PublicApplicationRoot />, domNode);
