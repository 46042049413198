import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import {
  resetBrandStyles,
  setBrandStyles,
} from '../../../../redux/domains/publicApplication/brandStyles';
import { messageFromError } from '../../../../redux/errors/helpers';
import { usePublicApplicationDispatch } from '../../../../redux/hooks';
import { useBrandStylesQuery } from '../../../../redux/services/resourceApis/publicApplication/loginApi';
import { useRoute } from '../applicationRouter';

// Add the route key from the applicationRouter.ts to PAGES_WITH_BRAND_STYLES to apply brand styles on this page
// (it's important to have a slug or account_id in the URL to fetch brand styles)
const PAGES_WITH_BRAND_STYLES = ['forgotPassword', 'login', 'resetPassword', 'acceptInvitation'];

const useBrandStyles = () => {
  const { name, params } = useRoute();
  const dispatch = usePublicApplicationDispatch();
  const isPageWithBrandStyles = name && PAGES_WITH_BRAND_STYLES.includes(name);
  const slugParam = 'slug' in params && params.slug;
  const accountIdParam = 'account_id' in params ? params.account_id : undefined;
  const brandStylesQueryResult = useBrandStylesQuery(
    slugParam && isPageWithBrandStyles ? { slug: slugParam, account_id: accountIdParam } : skipToken
  );
  const {
    isLoading: isLoadingBrandStyles,
    error: brandStylesError,
    data: brandColors,
  } = brandStylesQueryResult;

  useDisplayFlashOnResponse({
    result: brandStylesQueryResult,
    errorMessage: messageFromError(brandStylesError)?.join(', '),
  });

  useEffect(() => {
    if (isPageWithBrandStyles && brandColors) {
      dispatch(setBrandStyles(brandColors));
    } else {
      dispatch(resetBrandStyles());
    }
  }, [brandColors, dispatch, isPageWithBrandStyles]);

  return isLoadingBrandStyles;
};

export default useBrandStyles;
